/* src/components/Map.css */
.map-container {
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  position: absolute;
  top: 0;
}

.map-container canvas {
  height: 100vh !important;
  width: 100% !important;
}
