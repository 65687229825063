@import '@radix-ui/themes/styles.css';

body {
  font-family: Arial, sans-serif;
  margin: 0;
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden; /* Prevent body scroll */
  font-size: 0.9rem;
  line-height: 130%;
  letter-spacing: 0.03em;
}

strong {
  font-weight: bold;
}

img {
  max-width: 100%;
  max-height: 100%;
}
