.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  overflow-wrap: break-word; /* Wraps long words and URLs */
}

.modal-content {
  background-color: #fff;
  margin: 10% auto;
  /* padding: 20px; */
  /* border: 1px solid #888; */
  /* width: 80%; */
  max-width: 600px;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
  animation: modalopen 0.4s;
}

@keyframes modalopen {
  from {transform: translateY(-50px); opacity: 0;}
  to {transform: translateY(0); opacity: 1;}
}


.modal img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.modal ul {
  list-style-type: none;
  padding: 0;
}

.modal li {
  margin-bottom: 10px;
}

