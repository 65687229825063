
.navbar {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  top: 0;
  position: relative;
  z-index: 99999;
  grid-gap: 10px;
}

#locationInfo {
  display: flex;
  grid-gap: 10px;
}

.logo {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 99999;
  grid-gap: 10px;
}

.location {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 99999;
  grid-gap: 10px;
}

.navbar-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
}


.info-icon {
  position: absolute;
  top: 9px;
  left: 10px;
  z-index: 20;
  padding: 8px;
  color: #00000057;
  font-size:  0.9rem;
  cursor: pointer;
  border-radius: 5px;
}

.info-icon:hover {
  background-color: #bbb;
}

.control-icons {
  position: absolute;
    top: 10px;
    display: flex;
    right: 10px;
    z-index: 20;
}

.icon {
  padding: 8.5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: lighter;
  color: #00000057;
    font-size:  0.9rem;

}

.icon:hover {
  background-color: #bbb;
}



.modal {
  display: none;
  position: fixed;
  z-index: 30;
  right: 0;
  top: 0;
  width: 33vw;
  height: 100vh;
  overflow: auto;
  background: white;
}

.modal-content {
  margin: 0;
  padding: 2rem;
  background: #fff;
  position: relative;
  top: 3rem;
}

.close {
  position: absolute;
  margin: 1rem 1.5rem;
  top: 0;
  right: 0;
  font-weight: lighter;
  cursor: pointer;
  color: #00000057;
  font-size: 1rem;
  line-height: 130%;
  letter-spacing: 0.03em;
}

.truncate-link {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.object-grid.small-grid .object-card {
  width: 10vw;
  max-width: 10vw;
  height: auto;
}

.object-grid.small-grid .object-card img {
  max-height: 10vh;
}

