/* Filter.css */
.filter {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 3rem;
  position: relative;
  z-index: 999;
  top: 0;
  margin-top: -4rem;
  left: 0;
  background: white;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  height: auto; /* or any other height limit */
}

.table {
  width: 100%;
}

.cell {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid;
}

.field-name {
  font-size: 0.7rem;
  line-height: 100%;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.5);
}

.cell-content {
}

.cell-content div {
  cursor: pointer;
  padding: 0.05rem 0;
}

.cell-content div:hover {
  text-decoration: underline;
  color: #007bff;
}
