.object-grid {
  width: 100%;
    overflow-y: auto;
    padding: 0;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    transition: height 0.3s;
    z-index: 9;
    height: 100vh;
}

.object-grid::after {
  content: "";
  flex: auto;
}

/* Optional: Adjust specific alignment for the last row */
.object-grid:last-child {
  display: flex;
  justify-content: flex-start; /* Align the last row items to the left */
  gap: 2rem; /* Maintain space between items */
  width: 100%;
}
  
  .object-card {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width:150px;
    height: 150px;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
  }
  
  .object-card img {
    width:100%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 5px;
    /* object-position: 100% 0%; */
  }
  
  .object-info {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 5px;
  }
  
  .object-info span {
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
  }
  
  .object-info a {
    color: blue;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
