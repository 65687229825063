.search-container-div {
  position: absolute;
  top: 4rem;
  left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 3px;
  z-index: 20;
  width: 100%;
  height: 4rem;
  background: white;
  justify-content: center;
}

.search-icon {
  position: relative;
    left: 8px;
    top: 2px;
    color: #00000057;
}

.search-container {
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 0.7rem;
  background: rgb(118 118 128 / 12%);
  touch-action: manipulation;
  letter-spacing: 0.03em;
  -webkit-user-select: none;
  user-select: none;
  height: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.search-container input[type="text"] {
  border: none;
    outline: none;
    border-radius: 10px;
    margin-left: 16px;
    font-size: 0.9rem;
    background: rgba(118, 118, 128, 0);
    max-width: 900px;
    touch-action: manipulation;
    letter-spacing: 0.03em;
    -webkit-user-select: none;
    user-select: none;
    height: 2rem;
}
